<template>
  <b-navbar
    transparent
    centered
  >
    <template #brand>
      <b-navbar-item>
        <!-- <img
          src="../../assets/logo.png"
          alt="Lightweight UI components for Vue.js based on Bulma"
        /> -->
        <b-icon
          icon="bars"
          @click.native="$emit('togglenav')"
        />
      </b-navbar-item>
    </template>
    <template #start>
      <b-navbar-item class="has-text-light">
        <!-- <img
          src="../../assets/logo.png"
          alt="Lightweight UI components for Vue.js based on Bulma"
        />  -->
        <router-link
          to="/"
          class="subtitle"
        >
          INVOLUTE
        </router-link>
      </b-navbar-item>
    </template>

    <template #end>
      <b-navbar-item tag="div">
        <b-button
          size="is-small"
          class="button is-primary"
          @click="logout()"
        >
          Sign out
        </b-button>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Navbar",
  methods: {
    ...mapActions(["logout"]),
  },
};
</script>

<style lang="scss" scoped>
nav {
  background-color: rgba($color: #000000, $alpha: 0);
}

.navbar-item {
  color: white;
}

// .navbar-dropdown {
//   color: white
// }
</style>
