import axios from "axios"
// import router from "../router"

export default {
    // namespaced: true,
    state: {
        errormessage: "",
        uid: null,
        genericloader: false,
        // isAuthenticated: false,,
        environment: process.env.NODE_ENV
    },
    getters: {
        isAuthenticated(state) {
            return !!state.uid
        },
        genericloader(state) {
            return state.genericloader
        },
        errormessage(state) {
            return state.errormessage
        },
        uid(state) {
            return state.uid
        },
    },
    mutations: {
        authUser(state, payload) {
            // console.log(this.state.idToken)
            state.uid = payload.uid

        },

        clearAuthData(state) {
            state.uid = null
        },

        clearerrormessage(state) {
            state.errormessage = null
        },


        toggleLoader(state, payload) {
            state.genericloader = payload
        },

        updateErrormessage(state, payload) {
            state.errormessage = payload
        },

    },
    actions: {

        async postLogin(context, apiData) {
            try {
                const response = await axios.post("/insurerlogin", apiData)

                if (response.status == 200) {
                    context.commit("authUser", {
                        uid: response.headers.token
                    })
                    localStorage.setItem("uid", response.headers.token)

                }
            } catch (error) {
                console.log(error)
                // define specific error handling actions here
            }
        },

        // async commituser(context, response) {
        //     context.commit("authUser", {
        //         uid: response.headers.token
        //         // emailaddress: response.data.data.emailaddress
        //     })

        //     localStorage.setItem("uid", response.headers.token)
        //     // localStorage.setItem('emailaddress', response.data.data.emailaddress)
        //     // router.push('/dashboard')
        //     // dispatch('fetchUser')
        // },

        async tryAutoLogin(context) {

            const uid = localStorage.getItem("uid")


            if (uid) {
                // context.commit('authUser', {uid: uid})
                context.commit("authUser", {
                    uid: uid
                });

            }

        },

        // logout(context) {
        //     context.commit("clearAuthData");
        //     localStorage.removeItem("uid");
        //     // localStorage.clear()
        //     router.push("/");
        //     location.reload();

        // },

    }
}
